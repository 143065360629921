import { gql } from '@apollo/client'

export const NEWS = gql`
  query News($id: ID!, $idType: PostIdType, $asPreview: Boolean) {
    news: post(id: $id, idType: $idType, asPreview: $asPreview) {
      id
      title
      content
      date
      categories {
        nodes {
          name
        }
      }
    }
  }
`

export const NEWS_LIST = gql`
  query NewsList($where: RootQueryToPostConnectionWhereArgs) {
    newsList: posts(where: $where) {
      nodes {
        id
        uri
        title
        date
      }
      pageInfo {
        offsetPagination {
          total
        }
      }
    }

    categories(where: { hideEmpty: true }) {
      nodes {
        id
        name
      }
    }
  }
`
