import React, { useEffect } from 'react'
import { useLocation } from '@reach/router'
import queryString from 'query-string'
import { navigate } from 'gatsby'
import { useApolloClient, useLazyQuery } from '@apollo/client'
import { NEWS } from '@/apollo/queries/news'
import { NewsQuery, NewsQueryVariables, PostIdType } from '@/apollo/types'
import { css, useTheme } from '@emotion/react'
import { createAuthLink } from '@/apollo/links/auth-link'
import { httpLink } from '@/apollo/links/http-link'
import { Container } from '@/components/container'
import { formatDate } from '@/helpers/format-date'
import { PageHeader } from '@/components/page-header'
import { BackBlock } from '@/components/back-block'

export default function NewsPage() {
  const theme = useTheme()
  const { search } = useLocation()
  const { id, preview, token } = queryString.parse(search)
  const authToken = preview ? decodeURIComponent(token as string) : null

  const apollo = useApolloClient()

  const [getNews, { data, previousData, loading }] = useLazyQuery<
    NewsQuery,
    NewsQueryVariables
  >(NEWS)
  const { title, content, date } = data?.news ?? previousData?.news ?? {}

  useEffect(() => {
    const isPreview = preview === 'true'

    if (id) {
      if (authToken) {
        // GraphQLに認証ヘッダーを追加する
        const authLink = createAuthLink(authToken)
        apollo.setLink(authLink.concat(httpLink))
      }

      getNews({
        variables: {
          id: String(id),
          idType: isPreview ? PostIdType.DatabaseId : PostIdType.Id,
          asPreview: isPreview,
        },
      })
    } else {
      navigate('/404')
    }
  }, [id, preview, authToken])

  if (loading) {
    return (
      <div
        css={css`
          position: fixed;
          top: 0;
          left: 0;
          z-index: 1000;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100vw;
          height: 100vh;
          background-color: ${theme.colors.white};
        `}
      >
        <h1>Loading...</h1>
      </div>
    )
  }

  return (
    <article>
      <PageHeader underlineColor="magenta">新着情報</PageHeader>

      <div
        css={css`
          padding: 12rem 4rem 19rem;

          @media ${theme.breakpoints.md} {
            padding-top: 25rem;
          }
        `}
      >
        <Container>
          <div
            css={css`
              font-family: ${theme.fonts.roboto};
              letter-spacing: ${theme.letterSpacings.tight};
              color: ${theme.colors.magenta.main};
            `}
          >
            {formatDate(date ?? '')}
          </div>
          <h1
            css={css`
              font-size: ${theme.fontSizes.heading3};
              font-weight: ${theme.fontWeights.bold};
              border-bottom: solid 1px ${theme.colors.gray.light};
              padding: 0.8rem 0 1.2rem;
              margin-bottom: 1.6rem;
            `}
          >
            {title}
          </h1>
          <div
            dangerouslySetInnerHTML={{ __html: content ?? '' }}
            css={css`
              line-height: ${theme.lineHeight.relaxed};

              & > * {
                margin-top: 2rem;
              }

              img,
              video {
                width: 100%;
                height: auto;
                object-fit: contain;
              }

              a {
                color: ${theme.colors.magenta.main};
                text-decoration: underline;
              }

              ul {
                & > li {
                  list-style: disc;

                  & > ul > li {
                    list-style: square;
                  }
                }
              }

              ol {
                & > li {
                  list-style: decimal;

                  & > ol > li {
                    list-style: lower-alpha;
                  }
                }
              }

              h1,
              h2,
              h3,
              h4,
              h5,
              h6,
              strong {
                font-weight: bold;
              }

              em {
                font-style: italic;
              }

              sup {
                vertical-align: super;
                font-size: smaller;
              }

              sub {
                vertical-align: sub;
                font-size: smaller;
              }

              blockquote {
                border-left: solid 4px black;
                padding-left: 2rem;

                cite {
                  font-size: 0.8em;
                }
              }

              code {
                padding: 2rem;
                background: #f0f0f1;
              }

              kbd {
                padding: 0.2em 0.4em;
                background: #e0e0e0;
                border-radius: 4px;
              }

              .wp-block-columns {
                display: flex;

                .wp-block-column {
                  flex: 1;
                }
              }

              .wp-block-button__link {
                color: ${theme.colors.black};
                border: solid 2px currentColor;
                border-radius: 9999px;
                padding: 1.6rem 4rem;
                text-decoration: none;
                transition: all 0.25s ease-out;

                &:hover {
                  color: ${theme.colors.white};
                  background-color: ${theme.colors.black};
                }
              }

              h1 {
                font-size: 3.2rem;
              }

              h2 {
                font-size: 2.6rem;
              }

              h3 {
                font-size: 2.4rem;
              }

              h4 {
                font-size: 2.2rem;
              }

              h5 {
                font-size: 2rem;
              }

              h6 {
                font-size: 1.8rem;
              }
            `}
          />
        </Container>

        <BackBlock
          css={css`
            margin-top: 16rem;
          `}
        />
      </div>
    </article>
  )
}
